<template>
  <v-container class="py-0">
    <v-sheet elevation="0" class="d-flex">
      <v-row class="pa-4 my-0" align="center">
        <v-col cols="8" align-self="center">
          <div class="d-flex flex-row flex-wrap wc-body-2 font-weight-medium">
            <v-tooltip top color="#f44336">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  v-if="affiliate.isPendingPayment"
                  icon
                  color="red"
                  :to="`/member/newAffiliate/2/${affiliate._id}/${userMembership.organization.id}`"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="red"> mdi-alert-octagon </v-icon>
                </v-btn>
              </template>
              <span>
                Continua el proceso de pago de tu afiliado para activar su
                subscripción
              </span>
            </v-tooltip>

            <span
              class="d-flex mx-2 text-uppercase wc-body-2 text-primary-variant font-weight-black"
              >{{ affiliate.name }} - {{ affiliate.firstLastname }}</span
            >
            <span class="d-flex mx-2">
              <span class="wc-body-2 text-primary-variant font-weight-black"
                >F. Inicio -</span
              >
              {{ moment(affiliate.createdAt).format("YYYY-MM-DD") }}
            </span>
            <span class="d-flex mx-2">
              <span class="wc-body-2 text-primary-variant font-weight-black"
                >Cargo -</span
              >
              {{ affiliate.position }}
            </span>
            <span class="d-flex mx-2">
              <span class="wc-body-2 text-primary-variant font-weight-black"
                >Usuario -</span
              >
              {{ affiliate.email }}
            </span>
          </div>
        </v-col>
        <v-col cols="1" class="pl-5">
          <v-row justify="center">
            <v-chip
              :color="
                homologateStatusWithColor(affiliate.validationStatus).color
              "
              class="rounded-lg font-weight-bold"
            >
              {{ homologateStatusWithColor(affiliate.validationStatus).status }}
            </v-chip>
          </v-row>
        </v-col>
        <v-col cols="3" align-self="center">
          <v-row justify="center">
            <v-btn
              icon
              class="ml-2"
              :color="color.primaryVariant"
              @click="$emit('delete', affiliate)"
            >
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
            <v-btn
              icon
              class="ml-2"
              :color="color.primaryVariant"
              @click="$emit('edit', affiliate)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              icon
              class="ml-2"
              :color="color.primaryVariant"
              :disabled="!affiliate.isPendingPayment"
              :to="`/member/newAffiliate/2/${affiliate._id}/${userMembership.organization.id}`"
            >
              <v-icon>mdi-currency-usd</v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>
    <v-divider></v-divider>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { colors } from "@/constants/colors.js";
import {
  homologateStatusWithColor,
  isActivePaymentNationalH,
  isActivePaymentLocalH,
} from "@/common/hook/useHelper.js";

export default {
  props: {
    affiliate: {
      type: Object,
      default: {},
    },
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("membershipProfile", ["userMembership"]),
    needsPayment() {
      
    },
  },
  methods: {
    homologateStatusWithColor,
    isActivePaymentNationalH,
    isActivePaymentLocalH,
  },
  async mounted() {
    this.affiliation = this.affiliate.affiliation;
  },
  watch: {
    affiliation(val) {
      if (val) {
        this.urlNextPayment = `/member/payment/${this.affiliate.affiliation.local._id}/${this.affiliate._id}`;

        this.isActivePaymentNational = this.isActivePaymentNationalH(val);
        this.isActivePaymentLocal = this.isActivePaymentLocalH(val);

        switch (this.user.headOffice.paymentType) {
          case "NATIONAL":
            this.isDisabledPayment = !this.isActivePaymentNational;
            break;
          case "LOCAL":
            this.isDisabledPayment = !this.isActivePaymentLocal;
            break;
          case "BOTH":
            this.isDisabledPayment =
              !this.isActivePaymentLocal && !this.isActivePaymentNational;
            break;
          default:
            this.isDisabledPayment = true;
        }

        if (this.isActivePaymentNational) {
          this.typePayment = "nacional";
          this.urlNextPayment = `/member/payment/${this.affiliation.national._id}/${this.affiliate._id}`;
        }
      }
    },
  },
  data() {
    return {
      color: colors,
      isActivePaymentNational: false,
      isActivePaymentLocal: false,
      isDisabledPayment: true,
      affiliation: {},
      classes: {
        bold: "font-weight-black indigo--text text--darken-4",
        ligth: "blue-grey--text text--darken-1",
      },
    };
  },
};
</script>

<style>
.v-input__slot {
  align-items: center;
  justify-content: center;
}

.text-vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>