<template>
  <v-container>
    <v-row justify="space-between" align="center" class="pb-4">
      <v-col cols="12" sm="6" md="4">
        <v-sheet color="accent rounded-xl pl-3">
          <v-text-field v-model="pagination.name" solo dense placeholder="Buscar Afiliado" hide-details="auto"
            class="rounded-search" clearable v-on:keypress.enter="getFiltereAffiliates()">
            <template v-slot:prepend>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon small v-on="on" disabled>
                    <v-icon>fa fa-search</v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-sheet>
      </v-col>

      <v-col cols="12" md="5">
        <v-row>
          <v-col cols="6">
            <v-select v-model="pagination.validationStatus" :items="statusItems" clearable placeholder="Estatus" solo
              dense :color="color.primaryVariant" :item-color="color.primaryVariant" hide-details="auto"
              @change="getFiltereAffiliates()" />
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" sm="6" md="3" v-if="!isCanacoLeonMember">
        <v-btn to="/member/newAffiliate/1" rounded :color="color.secondary">
          <v-icon>mdi-plus</v-icon>
          <span class="body-2 font-weight-bold">
            Nuevo Afiliado Vinculado
          </span>
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-pagination v-model="pagination.page" :length="pagination.totalPages || 1" @input="getFiltereAffiliates()"
          total-visible="10" style="width: 100%" class="my-2" />
      </v-col>
    </v-row>
    <v-row v-for="(affiliate, i) in affiliates" :key="i" class="py-0 my-0">
      <AffiliateSheet :affiliate="affiliate" @delete="handleDelete" @edit="openEditModal"></AffiliateSheet>
    </v-row>
    <ModalTemplate @close="closeModal" @continue="handleContinue" :open="openModal" :primaryBtnProps="{
      text: 'Guardar',
      show: true,
      class: 'ml-2',
    }" :secondaryBtnProps="{
  text: 'Cerrar',
  show: true,
}">
      <template #content>
        <div class="d-flex flex-column align-stretch">
          <p class="text-h3 align-self-center">
            {{ formCreate ? "Agregar Afiliado" : "Actualizar Afiliado" }}
          </p>
          <v-form ref="newAffiliateForm">
            <v-text-field v-model="newAffiliate.user.name" label="Nombre" filled rounded />
            <v-text-field v-model="newAffiliate.user.firstLastname" label="Apellido" filled rounded />
            <v-text-field v-model="newAffiliate.user.email" label="Correo Electrónico" type="email" filled rounded />
            <v-text-field v-model="newAffiliate.user.position" label="Cargo" filled rounded />
          </v-form>
        </div>
      </template>
    </ModalTemplate>
    <v-dialog v-model="deleteAffiliateDialog" width="600">
      <DeleteDialog @close="deleteAffiliateDialog = false" @continue="DeleteAffiliate" :affiliate="affiliateToDelete"
        :loading="loadingDelete"></DeleteDialog>
    </v-dialog>
  </v-container>
</template>
<script>
import ActionButton from "../../components/shared/ActionButton.vue";
import AffiliateSheet from "../../components/memberDashboard/affiliates/affiliateSheet.vue";
import DeleteDialog from "../../components/memberDashboard/affiliates/DeleteDialog.vue";
import ModalTemplate from "../../components/shared/ModalTemplate.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import { colors } from "@/constants/colors.js";
export default {
  components: {
    ActionButton,
    AffiliateSheet,
    ModalTemplate,
    DeleteDialog,
  },
  data() {
    return {
      busqueda: "",
      affiliates: [],
      openModal: false,
      newAffiliate: {
        user: {
          name: "",
          firstLastname: "",
          email: "",
          position: "",
        },
      },
      formCreate: true,
      color: colors,
      pagination: {
        page: 1,
        limit: 10,
        totalPages: 0,
        name: "",
        branch: "",
        validationStatus: "",
      },
      statusItems: [
        { text: "Prospecto", value: "PROSPECTO" },
        { text: "Activo", value: "ACTIVE" },
        { text: "Inactivo", value: "INACTIVO" },
        { text: "Expirado", value: "EXPIRED" },
      ],
      selectedStatus: "",
      deleteAffiliateDialog: false,
      affiliateToDelete: {},
      loadingDelete: false,
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("membershipProfile", ["userMembership"]),
    isCanacoLeonMember() {
      const canacoLeonId = '6500e21c80d167001bf44b63'

      return this.user.headOffice._id == canacoLeonId
    },
    isActiveAddNewSubMember() {
      if (this.user.role == "MEMBER") {
        return false;
        //return this.user.memberInformation.sub_member_limit == this.user.memberInformation.sub_members_used
      }
    },
  },
  methods: {
    ...mapActions("memberAffiliates", [
      "getAffiliates",
      "addAffiliate",
      "deleteAffiliate",
      "updateAffiliate",
    ]),
    ...mapActions("user", ["realoadUserData"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapMutations("notification", ["show"]),

    handleDelete(affiliate) {
      this.affiliateToDelete = affiliate;
      this.deleteAffiliateDialog = true;
    },
    openEditModal(affiliate) {
      let affiliateCopy = JSON.parse(JSON.stringify(affiliate));
      this.openModal = true;
      this.formCreate = false;
      this.newAffiliate.user = affiliateCopy;
    },
    closeModal() {
      this.openModal = false;
      this.newAffiliate.user = {
        name: "",
        firstLastname: "",
        email: "",
        position: "",
      };
    },
    async handleContinue() {
      if (this.formCreate) {
        this.AddAffiliate();
      } else {
        this.UpdateAffiliate();
      }
    },
    async AddAffiliate() {
      try {
        this.loading();
        const affiliate = await this.addAffiliate(this.newAffiliate);
        if (affiliate.status) {
          this.closeModal();
        } else {
          this.show({
            text: affiliate.message,
            color: "red",
          });
          console.log(affiliate.message);
        }
        this.loaded();
      } catch (error) {
        console.log(error);
      } finally {
        await this.realoadUserData({ email: this.user.email });
      }
    },
    async UpdateAffiliate() {
      try {
        this.loading();
        const updatedAffiliate = await this.updateAffiliate(this.newAffiliate);
        if (updatedAffiliate.status) {
          this.affiliates = this.affiliates.map((affiliate) => {
            if (affiliate._id == updatedAffiliate.affiliate._id) {
              return updatedAffiliate.affiliate;
            } else {
              return affiliate;
            }
          });
          this.closeModal();
        } else {
          console.log(updatedAffiliate.message);
        }
        this.loaded();
      } catch (error) {
        console.log(error);
      } finally {
        await this.realoadUserData({ email: this.user.email });
      }
    },
    async DeleteAffiliate(affiliate) {
      try {
        this.loadingDelete = true;
        const affiliateToDelete = await this.deleteAffiliate(affiliate);
        if (affiliateToDelete.status) {
          this.affiliates.forEach((element) => {
            if (element._id == affiliate._id) {
              element.validationStatus = "DELETED";
            }
          });
        } else {
          console.log(affiliateToDelete.message);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingDelete = false;
        this.deleteAffiliateDialog = false;
        await this.realoadUserData({ email: this.user.email });
      }
    },
    async getFiltereAffiliates() {
      try {
        this.loading();
        this.pagination.branch = this.userMembership.organization._id
        console.log(this.pagination);
        const affiliates = await this.getAffiliates(this.pagination);

        if (affiliates.status) {
          this.affiliates = affiliates.affiliates.data.members;
          //console.log('affiliates.affiliates.data',affiliates.affiliates.data)
          this.pagination.totalPages = affiliates.affiliates.data.totalPages
        } else {
          console.log(affiliates.message);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loaded();
      }
    },
  },
  async created() {

    this.getFiltereAffiliates()

  },
};
</script>

<style>
.rounded-search {
  border-radius: 0px 24px 24px 0px !important;
}

.event-container {
  min-height: 93vh;
}
</style>